import { HeadMetadata } from "@/components/HeadMetadata";
import { akony } from "@/utils/fonts/fonts";
import { GetStaticProps, NextPage } from "next";
import Link from "next/link";
import RiArrowLeftLine from '~icons/ri/arrow-left-line'
import { NextPageWithLayout } from "./_app";
import { ReactElement } from "react";
import RootLayout from "./RootLayout";

interface Props {
    siteUrl: string;
}
  
const NotFound: NextPageWithLayout<Props> = (props) => {
    return (
        <>
            <HeadMetadata 
                canonicalUrl={props.siteUrl}
                pageTitle='Страница не найдена › Тиджой'
                pageDescription='Искомая вами страница не найдена'
                robots='noindex,follow'
            />
            <section className="flex flex-col mx-auto mt-32 justify-center items-center">
                <h1 className="text-[5rem] leading-none" style={akony.style}>404</h1>
                <p>Здесь нет ничего кроме маленького хот-дога 🌭...</p>
                <Link
                    href="/"
                    className="flex flex-row items-center justify-center mx-auto text-xs md:text-base gap-3 hover:bg-[#282828]/50
                    bg-[#151515]/50 rounded-2xl px-5 p-2 md:px-8 border hover:border-white/30 border-white/10 w-fit mt-5">
                        <RiArrowLeftLine/> вернуться назад 
                </Link>
            </section>
        </>
    )
} 

NotFound.getLayout = function getLayout(page: ReactElement) {
    return (
        <RootLayout>{page}</RootLayout>
    )
}  

export default NotFound;

export const getStaticProps: GetStaticProps<Props> = async () => {
  return {
    props: {
      siteUrl: process.env['SITE_URL'] as string,
    },
  };
};